$max-width: 600px !default;
$border-radius: 12px !default;
$button-border-radius: 12px !default;

$purple: #971C5F !default;
$grey: #A2BDCB !default;
$light-grey: #E5E5E5 !default;
$blue: #278ACE !default;
$dark-blue: #01498B !default;
$green: #99C305 !default;
$yellow: #ffd400 !default;
$orange: #ca3814 !default;
$pink: #ED2E96 !default;

$primary-color: $purple !default;
$secondary-color: $green !default;

$background: #FFFFFF !default;
$background-modal: #FFFFFF !default;
$top-bar-color: $primary-color !default;
$tab-bar-background: $background !default;
$tab-bar-color: $primary-color !default;
$text-color: #011E38 !default;

$button-primary: $primary-color !default;
$button-secondary: $secondary-color !default;
$button-light-grey: #CFC7C7 !default;
$button-red: #D43228 !default;
$button-disabled: #F5F3F3 !default;
