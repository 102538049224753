@mixin entry() {
  @include margin-bottom(15px);

  & > .title {
    font-size: 18px;
    font-weight: 700;
  }

  & > .attachment {
    & > .image {
      & > img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  & > .actions {
    margin-top: 30px;
  }
}

club, activities, entry {
  display: block;

  & > .club {
    @include margin-bottom(20px);

    & > .segment {
      background: $background;
      padding: 10px 15px;
      margin-top: -20px;
      margin-left: -15px;
      margin-right: -15px;
      border-bottom: 1px solid $light-grey;

      & > .tabs {
        background: $light-grey;
        display: flex;
        align-items: center;
        border-radius: $button-border-radius;
        overflow: hidden;

        & > .tab {
          flex: 1 1 50%;
          border-radius: 0px;

          &.active {
            background: $button-primary;
            color: #fff;
            font-weight: 700;
            opacity: 1;
          }
        }
      }
    }

    & > .entries {
      @include margin-bottom(30px);

      & > .date {
        display: flex;
        align-items: center;
        border: 2px solid $secondary-color;
        border-radius: $button-border-radius;
        overflow: hidden;

        & > .next > .button {
          border-radius: 0px;
        }
        & > .prev > .button {
          border-radius: 0px;
        }

        & > .current {
          flex-grow: 1;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .entry {
        @include card();
        @include entry();

        & > .head {
          display: flex;
          align-items: center;

          & > .title {
            margin-left: 10px;
            flex-grow: 1;
            font-size: 15px;
            font-weight: 700;
          }

          & > .picto img {
            width: 40px;
          }

          & > .hours {
            background-color: white;
            font-weight: bold;
            padding: 10px;
            border-radius: 5px;
            white-space: nowrap;
          }
        }

        & > .info {
          text-align: center;
        }
      }

      &.posts {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -8px;
        @include margin-bottom(15px);

        & > .entry-wrapper {
          flex: 0 0 50%;
          padding: 0px 8px;

          &.wide {
            flex: 0 0 100%;
          }

          &:not(.wide) {
            & > .entry {
              & > .attachment {
                & > .image {
                  border-radius: $border-radius;
                  overflow: hidden;

                  img {
                    height: 100px;
                    object-fit: cover;
                  }
                }
              }
            }
          }

          & > .entry {
            & > .title {
              font-size: 16px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  & > .entry {
    @include entry();
  }
}
