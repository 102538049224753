.button {
  display: block;
  padding: 14px 20px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  border-radius: $button-border-radius;
  cursor: pointer;

  &.large {
    width: 100%;
  }

  &.small {
    padding: 10px 14px;
    font-size: 14px;
  }

  &.xsmall {
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 8px;
  }

  &.primary {
    background: $button-primary;
    color: #fff;
  }
  
  &.secondary {
    background: $button-secondary;
    color: #fff;
  }

  &.white {
    background: #fff;
    color: $primary-color;
  }

  &.light-grey {
    background: $button-light-grey;
    color: #fff;
  }

  &.red {
    background: $button-red;
    color: #fff;
  }

  &.grey {
    background: $grey;
    color: #fff;
  }

  &.yellow {
    background: $yellow;
  }

  &.blue {
    background: $blue;
    color: #fff;
  }

  &:not(:disabled):hover {
    opacity: .8;
  }

  &:disabled {
    opacity: .3;
  }
}
